import "./Gallary.scss";
import { images } from "../../../utils/images";
import Slider from "react-slick";
import { useRef, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { Parallax } from "react-scroll-parallax";
import { fileBaseUrl } from "../../../utils/apiData";

const Gallary = ({ homePage }) => {

  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const onNext = () => {
    sliderRef.current.slickNext();
  };

  const onPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="image_gallary p_t p_b" id="gallary">
      <div className="content_wrap">
        <div className="text_wrap">
          <h1 className="extra_large">
            {homePage?.gallery_sec_1_title || "-"}
          </h1>
          <p>{homePage?.gallery_sec_1_description || "-"}</p>
        </div>
        {
          homePage?.gallery_images?.length > 0 ? <Parallax speed={10}>
          <div className="gallary_slider">
         
            <Slider ref={sliderRef} {...settings}>
              {homePage?.gallery_images?.map((data, j) => {
                return (
                  <div
                    className={`gallary_slide ${
                      j === slideIndex + 1 ? "active" : ""
                    } ${slideIndex + 1 > j ? "left" : ""} ${
                      slideIndex + 1 < j ? "right" : ""
                    }`}
                    key={`${data} + ${j}`}
                  >
                    <div className="img_wrap">
                      {data && (
                        <img src={`${fileBaseUrl}/${data}`} alt="Gallary" loading="lazy" />
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="gallary_btn_line">
            <button
              type="button"
              onClick={onPrev}
              disabled={slideIndex + 1 === 1}
            >
              <MdArrowBack />
            </button>
            <div className="count">
              <p>
                <b>
                  {slideIndex + 1}/{homePage?.gallery_images?.length}
                </b>
              </p>
            </div>
            <button
              type="button"
              onClick={onNext}
              disabled={slideIndex + 1 === homePage?.gallery_images?.length}
            >
              <MdArrowForward />
            </button>
          </div>
        </Parallax> : <div className="no_data" style={{height:"300px"}}>
          <p className="text-center">Data not found...</p>
        </div>

        }
       
      </div>
    </section>
  );
};

export default Gallary;
