import { Parallax } from "react-scroll-parallax";
import "./Offering.scss";

const Offering = ({homePage}) => {
  return (
    <section className="offering">
      <div className="content_wrap p_t p_b">
        <Parallax translateX={[-10, 10]}>
          <h1 className="large">
            {homePage?.service_sec_1_title || "-"}
          </h1>
        </Parallax>
        <Parallax translateX={[10, -10]}>
        <h1 className="large">
            {homePage?.service_sec_2_title || "-"}
          </h1>
        </Parallax>
      </div>
    </section>
  );
};

export default Offering;
