import "./Emmities.scss";

import { GrStatusGoodSmall } from "react-icons/gr";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { GiWashingMachine } from "react-icons/gi";
import { GiSecurityGate } from "react-icons/gi";
import { BiCctv } from "react-icons/bi";
import { FaKitchenSet } from "react-icons/fa6";
import { PiGasCanThin } from "react-icons/pi";
import { LuMicrowave } from "react-icons/lu";
import { FaParking } from "react-icons/fa";
import { GiManualMeatGrinder } from "react-icons/gi";
import { FaGlassWaterDroplet } from "react-icons/fa6";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { MdBroadcastOnHome } from "react-icons/md";
import { GiKeyCard } from "react-icons/gi";

import { images } from "../../../utils/images";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { Fragment, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import { baseUrl, fileBaseUrl } from "../../../utils/apiData";

const Emmities = ({ dataList }) => {
  const [num, setNum] = useState(1);

  const onPrev = () => {
    setNum((prev) => {
      if (prev === 1) {
        return dataList?.length;
      } else {
        return prev - 1;
      }
    });
  };

  const onNext = () => {
    setNum((prev) => {
      if (prev === dataList?.length) {
        return 1;
      } else {
        return prev + 1;
      }
    });
  };

  const stopPropo = (e) => {
    e.stopPropagation();
  };

  return (
    <Fragment>
      <section className="emmities_title p_t" id="amenities">
        <div className="content_wrap">
          <h1 className="extra_large">Liv In Out Amenities</h1>
        </div>
      </section>
      <section className="emmities">
        <div className="content_wrap p_t p_b">
          {dataList?.length > 0 ? (
            <Fragment>
              <div className="emmities_wrap">
                {dataList?.map((data, j) => {
                  return (
                    <div
                      className={`card_parent ${j < num - 1 ? "out" : "come"}`}
                      key={data.id}
                      style={{
                        transform: `translate(calc(-50% + ${
                          (j - num) * 16
                        }px), ${5 * -j}px)`,
                        zIndex: `${dataList?.length - j}`,
                      }}
                      onClick={stopPropo}
                    >
                      <Parallax speed={j}>
                        <div className="emmities_card">
                          <div className="text">                         
                            <h3>
                              <b>{data?.name || "-"}</b>
                            </h3>
                            <p>{data?.description || "-"}</p>
                          </div>
                          <div className="img_wrap">
                            <img
                              src={`${fileBaseUrl}/${data?.image}`}
                              alt="Emmities"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  );
                })}
              </div>
              <div className="btn_line">
                <button type="button" onClick={onPrev} disabled={num === 1}>
                  <MdArrowBack />
                </button>
                <div className="count">
                  <p>
                    <b>
                      {num}/{dataList?.length}
                    </b>
                  </p>
                </div>
                <button
                  type="button"
                  onClick={onNext}
                  disabled={num === dataList?.length}
                >
                  <MdArrowForward />
                </button>
              </div>
            </Fragment>
          ) : (
            <p className="text-center" style={{ color: "#ffffff" }}>
              Data not found...
            </p>
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default Emmities;
