import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import ScrollTrigger from "react-scroll-trigger";

const Counter = ({ count }) => {
  const [value, setValue] = useState(0);
  const [countStatus, setCountStatus] = useState(false);

  useEffect(() => {
    if (countStatus) {
      const timeoutId = setTimeout(() => setValue(count), 500);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setValue(0);
    }
  }, [countStatus]);

  return (
    <ScrollTrigger
      onEnter={() => setCountStatus(true)}
      onExit={() => setCountStatus(false)}
    >
     <Odometer value={value} />
    </ScrollTrigger>
  );
};

export default Counter;
