export const baseUrl = " https://liolive.supagrow.in";
export const fileBaseUrl = "https://liolive.supagrow.in/lio";

export const contact = "api/storecontactapi";
export const home = "api/homepageapi";





