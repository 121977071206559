import "./App.scss";
import { Route, Routes } from "react-router-dom";
import LioHome from "./pages/lioHome/LioHome";
import { Fragment, useEffect, useRef, useState } from "react";
import PreLoader from "./component/preLoader/PreLoader";

function App() {
  const [preLoader, setPreLoader] = useState(true);

  useEffect(() => {
    setPreLoader(true);
    const timeoutId = setTimeout(() => {
      setPreLoader(false);
    }, 4000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Fragment>
      {preLoader ? (
        <PreLoader />
      ) : (
        <Routes>
          <Route path="/" element={<LioHome />} />
        </Routes>
      )}
    </Fragment>
  );
}

export default App;
