import "./TriSec.scss";
import { images } from "../../../utils/images";
import { Parallax } from "react-scroll-parallax";
import PrimaryBtn from "../../../component/primaryBtn/PrimaryBtn";
import { fileBaseUrl } from "../../../utils/apiData";

const TriSec = ({setContact, homePage }) => {
  return (
    <section className="tri_sec p_t p_b">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="text">
            <h1 className="extra_large"> {homePage?.be_part_title || "-"}</h1>

            <p>{homePage?.be_part_description || "-"}</p>
          </div>
          <div className="dummy"></div>
        </div>
        <div className="tri_grid">
          <div className="tri_info_card">
            <div className="img_wrap">
              <div className="overlay"></div>
              <img
                src={`${fileBaseUrl}/${homePage?.be_part_section_1_image}`}
                alt="Info"
                loading="lazy"
              />
            </div>
            <h1>{homePage?.be_part_section_1_title || "-"}</h1>
          </div>
          <Parallax speed={15}>
            <div className="tri_info_card mid">
              <div className="img_wrap">
                <div className="overlay"></div>
                <img
                  src={`${fileBaseUrl}/${homePage?.be_part_section_2_image}`}
                  alt="Info"
                  loading="lazy"
                />
              </div>
              <h1>{homePage?.be_part_section_2_title || "-"}</h1>
            </div>
          </Parallax>
          <div className="tri_info_card">
            <div className="img_wrap">
              <div className="overlay"></div>
              <img
                src={`${fileBaseUrl}/${homePage?.be_part_section_3_image}`}
                alt="Info"
                loading="lazy"
              />
            </div>
            <h1>{homePage?.be_part_section_3_title || "-"}</h1>
          </div>
        </div>
        <div className="btn_line">
          <PrimaryBtn theme="dark" title="Book Call" onClick={() => setContact(true)} />
        </div>
      </div>
    </section>
  );
};

export default TriSec;
