import PrimaryBtn from "../../../component/primaryBtn/PrimaryBtn";
import { fileBaseUrl } from "../../../utils/apiData";
import "./BookContact.scss";

const BookContact = ({ homePage, setContact }) => {
  return (
    <section className="book_contact">
      <div className="contact_block">
        <video
          src={`${fileBaseUrl}/${homePage?.property_sec_1_video}`}
          autoPlay
          muted
          loop
        ></video>
        <div className="overlay"></div>
        <h1>{homePage?.property_sec_1_title || "-"}</h1>
        <PrimaryBtn title="Property Tour" onClick={() => setContact(true)} />
      </div>
      <div className="contact_block">
        <video
          src={`${fileBaseUrl}/${homePage?.property_sec_2_video}`}
          autoPlay
          muted
          loop
        ></video>
        <div className="overlay"></div>
        <h1>{homePage?.property_sec_2_title || "-"}</h1>
        <PrimaryBtn title="Book Now" onClick={() => setContact(true)} />
      </div>
    </section>
  );
};

export default BookContact;
