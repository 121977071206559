import "./Review.scss";
import PrimaryBtn from "../../../component/primaryBtn/PrimaryBtn";
import { Parallax } from "react-scroll-parallax";
import Slider from "react-slick";
import { Fragment } from "react";
import { baseUrl, fileBaseUrl } from "../../../utils/apiData";

const Review = ({ homePage, dataList, setContact }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed:600,
    autoplaySpeed:6000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="review_sec p_t p_b" id="reviews">
      <video
        src={`${fileBaseUrl}/${homePage?.review_image}`}
        autoPlay
        muted
        loop
      ></video>
      <div className="overlay"></div>
      <div className="content_wrap">
        <Parallax translateX={[-5, 5]}>
          <h1>{homePage?.review_title_1 || "-"}</h1>
        </Parallax>
        <Parallax translateX={[5, -5]}>
          <h1>{homePage?.review_title_2 || "-"}</h1>
        </Parallax>
        <div className="space"></div>
        {dataList?.length > 0 ? (
          <Slider {...settings}>
            {dataList.map((data, j) => {
              return (
                <Fragment key={data.id}>
                  <div className="review_card">
                    <p>{data?.description || "-"}</p>
                    <div className="profile">
                      <div className="profile_img">
                      <img src={`${fileBaseUrl}/${data.image}`} alt="Profile" />
                      </div>
                    <p className="text-end mt-2">{data?.name || "-"}</p>
                    </div>

                  </div>
                </Fragment>
              );
            })}
          </Slider>
        ) : (
          <p className="text-center py-5 my-5" style={{ color: "#ffffff" }}>
            Review data not found...
          </p>
        )}

        <div className="btn_line">
          <a href="https://lio.live/#reviews" target="_blank">
          <PrimaryBtn title="Read more reviews"/>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Review;
