import "./Header.scss";
import Headroom from "react-headroom";
import { FiMenu } from "react-icons/fi";

import {images} from "../../utils/images";

const Header = ({handleShow}) => {
  const secData = [
    {
      id: "services",
      title: "Services",
    },
    {
      id: "rooms",
      title: "Rooms",
    },
    {
      id: "amenities",
      title: "Amenities",
    },
    {
      id: "properties",
      title: "Properties",
    },
    {
      id: "gallary",
      title: "Gallery",
    },
    {
      id: "reviews",
      title: "Reviews",
    },
    {
      id: "location",
      title: "Locations",
    },
  ];
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header>
      <button className="menu_btn" onClick={handleShow}>
        <FiMenu />
      </button>
      <Headroom>
        <section className="header">
          <div className="left">
            <a href="/" className="logo_wrap">
            <img src={images.logo} alt="Brand Logo" loading="lazy"/>
            </a>

            {secData.map((data) => {
              return (
                <p onClick={() => scrollToSection(data.id)} key={data.id}>
                  {data.title}
                </p>
              );
            })}
          </div>
        </section>
      </Headroom>
    </header>
  );
};

export default Header;
