import Offcanvas from "react-bootstrap/Offcanvas";
import { IoClose } from "react-icons/io5";
import "./TopDrower.scss";
import { images } from "../../utils/images";


const TopDrower = ({ show, handleClose }) => {

  const secData = [
    {
      id: "services",
      title: "Services",
    },
    {
      id: "rooms",
      title: "Rooms",
    },
    {
      id: "amenities",
      title: "Amenities",
    },
    {
      id: "properties",
      title: "Properties",
    },
    {
      id: "gallary",
      title: "Gallary",
    },
    {
      id: "reviews",
      title: "Reviews",
    },
    {
      id: "location",
      title: "Locations",
    },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });

      handleClose();
    }
  };
  return (
    <Offcanvas placement="top" show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
         <div className="logo_wraper">
          <img src={images.logo} alt="Brand Logo" />
         </div>
          <button type="button" onClick={handleClose}>
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <div className="dd_drower">
      {secData.map((data) => {
              return (
                <p onClick={() => scrollToSection(data.id)} key={data.id}>
                  {data.title}
                </p>
              );
            })}
      </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TopDrower;
