import "./Services.scss";
import Marquee from "react-fast-marquee";
import { Fragment } from "react";
import { Parallax } from "react-scroll-parallax";
import { fileBaseUrl } from "../../../utils/apiData";

import { GrUserManager } from "react-icons/gr";
import { IoMdPower } from "react-icons/io";
import { BsWater } from "react-icons/bs";
import { MdElectricBolt } from "react-icons/md";
import { FaWifi } from "react-icons/fa6";
import { FaRegBuilding } from "react-icons/fa";

const Services = ({ homePage, dataList }) => {
  const text1 = homePage?.sec_2_description_1 || "-";
  const text2 = homePage?.sec_2_description_2 || "-";

  const iconsList = [
    <GrUserManager />,
    <IoMdPower />,
    <BsWater />,
    <MdElectricBolt />,
    <FaWifi />,
    <FaRegBuilding />,
  ];

  return (
    <Fragment>
      <section className="services" id="services">
        <div className="content_wrap">
          {/* // screen > desktop  */}
          <div className="tri_grid">
            <div className="text">
              <Parallax speed={-5}>
                <p className="big m_t">{text1}</p>
              </Parallax>
            </div>
            <div className="img_wrap">
              <Parallax scale={[1, 1.2]}>
                <img
                  src={`${fileBaseUrl}/${homePage?.sec_2_image}`}
                  alt="Service"
                  loading="lazy"
                />
              </Parallax>
            </div>
            <div className="text b">
              <Parallax speed={5}>
                <p className="big">{text2}</p>
              </Parallax>
            </div>
          </div>
          {/* Screen < = desktop */}
          <div className="less_desktop">
            <div className="text">
              <p className="big m_t">{text1}</p>
            </div>
            <div className="img_wrap">
              <img
                src={`${fileBaseUrl}/${homePage?.sec_2_image}`}
                alt="Service"
                loading="lazy"
              />
            </div>
            <div className="text b">
              <p className="big">{text2}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="services_sec p_t p_b">
        {dataList?.length > 0 ? (
          <Fragment>
            <Marquee>
              {[...dataList, ...dataList]?.map((item, k) => {
                return (
                  <div className="marquee_card" key={k}>
                    {[...iconsList, ...iconsList][k]}
                    <p>{item.name}</p>
                  </div>
                );
              })}
            </Marquee>
            <Marquee direction="right">
              {[...dataList, ...dataList]?.map((item, k) => {
                return (
                  <div className="marquee_card" key={k}>
                    {[...iconsList, ...iconsList][k]}
                    <p>{item.name}</p>
                  </div>
                );
              })}
            </Marquee>
          </Fragment>
        ) : (
          <div className="content_wrap pt-5">
            <p className="text-center">Services not found...</p>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default Services;
