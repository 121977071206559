import logo from "../assets/images/logo.png";

import roomA from "../assets/images/room_a.jpg";
import roomB from "../assets/images/room_b.jpg";
import roomC from "../assets/images/room_c.jpg";

import SlideA from "../assets/images/slideA.jpg";
import SlideB from "../assets/images/slideB.jpg";
import SlideC from "../assets/images/slideC.jpg";
import SlideD from "../assets/images/slideD.jpg";

import pressA from "../assets/images/pressA.jpg";
import pressB from "../assets/images/pressB.jpg";
import pressC from "../assets/images/pressC.jpg";
import pressD from "../assets/images/pressD.jpg";

import footerA from "../assets/images/footerA.png";
import footerB from "../assets/images/footerB.png";

import landingBg from "../assets/images/landingBg.jpg";
import service from "../assets/images/services.webp";
import serviceA from "../assets/images/serviceA.webp";
import serviceB from "../assets/images/serviceB.webp";
import serviceC from "../assets/images/serviceC.webp";
import serviceD from "../assets/images/serviceD.webp";

import locationA from "../assets/images/locationA.webp";
import locationB from "../assets/images/locationB.webp";

export const images = {
  
  logo,

  roomA,
  roomB,
  roomC,

  SlideA,
  SlideB,
  SlideC,
  SlideD,

  pressA,
  pressB,
  pressC,
  pressD,

  footerA,
  footerB,

  landingBg,
  service,
  serviceA,
  serviceB,
  serviceC,
  serviceD,

  locationA,
  locationB,
};
