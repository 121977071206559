import { Fragment, useEffect, useState } from "react";
import "./PreLoader.scss";
import { motion } from "framer-motion";
import { images } from "../../utils/images";

const PreLoader = () => {
  const [logoView, setLogoView] = useState(false);

  useEffect(() => {
    setLogoView(true);
    const timeoutId = setTimeout(() => {
      setLogoView(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Fragment>
      {logoView ? (
        <section className="logo_loader">
          <div className="content_wrap">
            <motion.img
              initial={{ opacity: 0.5 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeIn" }}
              src={images.logo}
              alt="Brand Logo"
              loading="lazy"
            />
          </div>
        </section>
      ) : (
        <section className="pre_loader">
          <div className="content_wrap">
            <div className="overlay"></div>
            <motion.h1
              initial={{ scale: 3 }}
              whileInView={{ scale: 3.8 }}
              transition={{ duration: 1.5, ease: "easeIn" }}
            >
              Your
              <br />
              kinda
              <br />
              Place
            </motion.h1>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default PreLoader;
