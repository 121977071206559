import "./Locations.scss";
import Slider from "react-slick";
import { images } from "../../../utils/images";
import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";
import { Fragment, useRef } from "react";
import { baseUrl, fileBaseUrl } from "../../../utils/apiData";

const Locations = ({ homePage, dataList }) => {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onNext = () => {
    sliderRef.current.slickNext();
  };

  const onPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="locations_sec" id="location">
      <div className="content_wrap p_t p_b">
        <h1 className="extra_large">{homePage?.location_title || "-"}</h1>

        {dataList?.length > 0 ? (
          <Fragment>
            <div className="location_slider">
              <Slider ref={sliderRef} {...settings}>
                {dataList?.map((data) => {
                  return (
                    <div className="location_pill" key={data.id}>
                      <img
                        src={`${fileBaseUrl}/${data?.image}`}
                        alt="Location"
                        loading="lazy"
                      />
                      <div className="overlay"></div>
                      <div className="content">
                        
                        <h3>
                          <b>{data.location || "-"}</b>
                        </h3>
                     
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="btn_lines">
              <button type="button" onClick={onPrev}>
                <MdArrowBack />
              </button>

              <button type="button" onClick={onNext}>
                <MdArrowForward />
              </button>
            </div>
          </Fragment>
        ) : (
          <p className="text-center mt-5">Data not found...</p>
        )}
      </div>
    </section>
  );
};

export default Locations;
