import "./Footer.scss";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { motion } from "framer-motion";
import { FaGoogle } from "react-icons/fa";

import { FaEye } from "react-icons/fa";

import { fileBaseUrl } from "../../utils/apiData";

import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";

const Footer = ({ homePage, setContact }) => {
  const email = "hello@livinout.in";
  const mobile = "+91 9090566566";

  return (
    <footer>
      <div className="top_strip"></div>
      <section className="footer">
        <div className="content_wrap">
          <div className="tri_grid">
            <div className="left">
              <div className="top">
                <h3>
                  <b>Let's get social!</b>
                </h3>
                <p>
                Stay updated with Liv In Out. Explore our social media channels, get in touch, or find answers to your questions.
                </p>
              </div>

              <div className="social">
                <a
                  href="https://www.instagram.com/livinout.in/"
                  target="_blank"
                >
                  <FaInstagram /> Instargram
                </a>
                <a href="https://www.linkedin.com/livinout/" target="_blank">
                  <FaLinkedinIn /> Linkedin
                </a>
                <a href="https://www.facebook.com/livinout.in/" target="_blank">
                  <FaFacebookF /> Facebook
                </a>
              </div>
            </div>
            <motion.div
              initial={{ scale: 0.5 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.3, ease: "easeIn" }}
              className="video_wrap"
            >
              <video
                src={`${fileBaseUrl}/${homePage?.footer_video}`}
                autoPlay
                muted
                loop
              ></video>
              <div className="overlay"></div>
              <button type="button" onClick={() => setContact(true)}>
                <span>
                  <FaEye />
                </span>
                Watch Our Story
              </button>
            </motion.div>
            <div className="right">
              <h1>Liv In Out</h1>

              <div className="links">
                <a href={`mailto:${email}`} target="_blank">
                  <MdEmail /> <p>{email}</p>
                </a>

                <a href={`tel:${mobile}`} target="_blank">
                  <IoCall />
                  <p>{mobile}</p>
                </a>
                <div className="address">
                  <IoLocationSharp />
                  <p>
                    Lio No 28, The Landmark, 5th floor, Assaye Road, Sindhi
                    colony, Cox town, Bangalore 560005
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
