import LioLanding from "./lioLanding/LioLanding";
import Header from "../../component/header/Header";
import Offering from "./offering/Offering";
import Services from "./services/Services";
import RoomTypes from "./roomTypes/RoomTypes";
import Emmities from "./emmities/Emmities";
import Properties from "./properties/Properties";
import BookContact from "./bookContact/BookContact";
import Gallary from "./galary/Gallary";
import Review from "./review/Review";
import TriSec from "./triSec/TriSec";
import Locations from "./locations/Locations";
import { Fragment, useEffect, useRef, useState } from "react";
import Counters from "./counters/Counters";
import Footer from "../../component/footer/Footer";
import ContactBtn from "../../component/contactBtn/ContactBtn";
import ContactPage from "../../component/contactPage/ContactPage";
import TopDrower from "../../component/topDrower/TopDrower";
import axios from "axios";
import { baseUrl, home } from "../../utils/apiData";
import { RiWhatsappFill } from "react-icons/ri";


const LioHome = () => {
  const [contact, setContact] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [amenities, setAmenities] = useState([]);
  const [homePage, setHomePage] = useState({});
  const [locations, setLocations] = useState([]);
  const [property, setProperty] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [services, setServices] = useState([]);

  const getLandingApi = async () => {
    try {
      const res = await axios.get(`${baseUrl}/${home}`);
      if (res?.data?.success) {
        setAmenities(res?.data?.data?.amenities);
        setHomePage(res?.data?.data?.homepage);
        setLocations(res?.data?.data?.location);
        setProperty(res?.data?.data?.property);
        setReviews(res?.data?.data?.review);
        setServices(res?.data?.data?.service);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLandingApi();
  }, []);

  return (
    <Fragment>
      {contact ? (
        <ContactPage homePage={homePage} locations={locations} setContact={setContact} />
      ) : (
        <Fragment>
         <TopDrower
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
          />
           <Header handleShow={handleShow} />



          {!show && <ContactBtn setContact={setContact} />}
          <LioLanding homePage={homePage} />
          <Offering homePage={homePage} />
          <Services homePage={homePage} dataList={services} />
          <RoomTypes homePage={homePage} setContact={setContact}/>
          <Emmities dataList={amenities} />
          <Properties dataList={property} />
          <BookContact homePage={homePage} setContact={setContact} />
          <Gallary homePage={homePage} />
          <Review
            homePage={homePage}
            dataList={reviews}
            setContact={setContact}
          />
          <Counters homePage={homePage} />
          <TriSec homePage={homePage} setContact={setContact} />
          <Locations homePage={homePage} dataList={locations} />
          <Footer homePage={homePage} setContact={setContact} /> 
        </Fragment>
      )}
      <a href="https://wa.me/+917996542399" target="_blank" className="whats_app_link">
      <RiWhatsappFill />
      </a>
    </Fragment>
  );
};

export default LioHome;
