import "./ContactBtn.scss";
import { motion } from "framer-motion";
import { useState } from "react";
import { SlCalender } from "react-icons/sl";

const ContactBtn = ({setContact}) => {
  const title1 = "Book Now";

  const [hover, setHover] = useState(false);

  return (
    <button
      type="button"
      className="contact_btn"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setContact(true)}
    >
      <span>
        <SlCalender />
      </span>

      {
           hover && <motion.p
            initial={{ translateY:16}}
            whileInView={{translateY:0}}
            transition={{ duration: 0.3, ease: "easeIn" }}
            className="dark"
          >
            {title1}
          </motion.p>
      }
      {
           !hover && <motion.p
            initial={{ translateY:16}}
            whileInView={{translateY:0}}
            transition={{ duration: 0.3, ease: "easeIn" }}
          >
            {title1}
          </motion.p>
      }



      
    </button>
  );
};

export default ContactBtn;
