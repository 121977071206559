import "./PrimaryBtn.scss";
import { IoIosArrowRoundDown } from "react-icons/io";
import { motion } from "framer-motion";
import { useState } from "react";

const PrimaryBtn = ({ title, theme, onClick }) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      type="button"
      className={`primary_btn ${theme}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <span>
        <IoIosArrowRoundDown />
      </span>

      {hover && (
        <motion.p
          initial={{ translateY: 16 }}
          whileInView={{ translateY: 0 }}
          transition={{ duration: 0.3, ease: "easeIn" }}
          className="dark"
        >
          {title}
        </motion.p>
      )}

      {!hover && (
        <motion.p
          initial={{ translateY: 16 }}
          whileInView={{ translateY: 0 }}
          transition={{ duration: 0.3, ease: "easeIn" }}
        >
          {title}
        </motion.p>
      )}
    </button>
  );
};

export default PrimaryBtn;
