import { Parallax } from "react-scroll-parallax";
import PrimaryBtn from "../../../component/primaryBtn/PrimaryBtn";
import "./LioLanding.scss";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { baseUrl, fileBaseUrl } from "../../../utils/apiData";
import { IoMdArrowForward } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { useRef } from "react";
const LioLanding = ({ homePage }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideRef = useRef();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const prev = () => {
    slideRef.current.slickPrev();
  }

  const next = () => {
    slideRef.current.slickNext();
  }

  return (
    <section
      className="lio_landing"
      style={{ background: `url(${fileBaseUrl}/${homePage?.main_image})` }}
    >
      <div className="overlay"></div>
      <div className="content_wrap">
        <motion.div
          initial={{ scale: 3 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          viewport={{ once: true }}
          className="landing_slider"
        >
          <Slider ref={slideRef} {...settings}>
            <div className="video_card">
              <video
                src={`${fileBaseUrl}/${homePage?.sec_1_video}`}
                autoPlay
                muted
                loop
              ></video>
              <div className="v_overlay"></div>
              <h3>{homePage?.sec_1_title || "-"}</h3>
            </div>

            <div className="video_card">
              <video
                src={`${fileBaseUrl}/${homePage?.sec_2_video}`}
                autoPlay
                muted
                loop
              ></video>
              <div className="v_overlay"></div>
              <h3>{homePage?.sec_2_title || "-"}</h3>
            </div>

            <div className="video_card">
              <video
                src={`${fileBaseUrl}/${homePage?.sec_3_video}`}
                autoPlay
                muted
                loop
              ></video>
              <div className="v_overlay"></div>
              <h3>{homePage?.sec_3_title || "-"}</h3>
            </div>
          </Slider>
        </motion.div>
        <div className="nex_prev">
        <button type="button" onClick={prev}><IoMdArrowBack /></button>
          <button type="button" onClick={next}><IoMdArrowForward /></button>
        </div>
        <div className="btn_line p_b">
          <a href="/#room">
            <PrimaryBtn
              title="Find your Home"
              onClick={() => scrollToSection("rooms")}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default LioLanding;
