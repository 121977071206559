import "./Properties.scss";
import { images } from "../../../utils/images";
import { Fragment, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";

import { FaRegBuilding } from "react-icons/fa";
import { MdAddBusiness } from "react-icons/md";
import { FaAcquisitionsIncorporated } from "react-icons/fa";
import { RiUserLocationFill } from "react-icons/ri";

import { MdArrowBack } from "react-icons/md";
import { MdArrowForward } from "react-icons/md";

import { motion } from "framer-motion";
import { baseUrl, fileBaseUrl } from "../../../utils/apiData";

const Properties = ({ dataList }) => {
  const [num, setNum] = useState(0);

  const prevHandler = () => {
    setNum((prev) => {
      if (prev === 0) {
        return dataList?.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const nextHandler = () => {
    setNum((prev) => {
      if (prev === dataList?.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };
  const theme = [
    "#183e48",
    "#347b8a",
    "#080808",
    "#1c2d49",
    "#2c466b",
    "#3c649f",
  ];
  const btnTheme = [
    "#347b8a",
    "#183e48",
    "#1c2d49",
    "#080808",
    "#3c649f",
    "#2c466b",
  ];
  return (
    <div id="properties">
      {dataList?.length > 0 ? (
        <Fragment>
          {dataList.map((data, j) => {
            return (
              <Fragment key={data.id}>
                {num === j && (
                  <section
                    className="properties p_t "
                    style={{
                      backgroundColor: `${theme[num % 6]}`,
                    }}
                  >
                    <div className="content_wrap">
                      <div className="bi_grid">
                        <div className="text_info">
                          <motion.div
                            className="location"
                            initial={{ scaleY: 0.2 }}
                            whileInView={{ scaleY: 1 }}
                            transition={{ duration: 0.3 }}
                            viewport={{ once: true }}
                          >
                            <IoLocationSharp /> <p>{data?.location || "-"}</p>
                          </motion.div>
                          <motion.h1
                            initial={{ scaleY: 0.2 }}
                            whileInView={{ scaleY: 1 }}
                            transition={{ duration: 0.3 }}
                            viewport={{ once: true }}
                            className="extra_large"
                          >
                            {data?.name || "-"}
                          </motion.h1>

                          <motion.div
                            className="info_flex"
                            initial={{ scaleY: 0.2 }}
                            whileInView={{ scaleY: 1 }}
                            transition={{ duration: 0.3 }}
                            viewport={{ once: true }}
                          >
                            <div className="sub_flex">
                              <FaRegBuilding /> <p>{data.title || "-"}</p>
                            </div>
                            <div className="sub_flex">
                              <MdAddBusiness /> <p>{data.title1 || "-"}</p>
                            </div>
                            <div className="sub_flex">
                              <FaAcquisitionsIncorporated />{" "}
                              <p>{data.title2 || "-"}</p>
                            </div>

                            <div className="sub_flex">
                              <RiUserLocationFill /> <p>{data.title || "-"}</p>
                            </div>
                          </motion.div>
                          <motion.h3
                            initial={{ scaleY: 0.2 }}
                            whileInView={{ scaleY: 1 }}
                            transition={{ duration: 0.3 }}
                            viewport={{ once: true }}
                          >
                            {data?.title4 || "-"}
                          </motion.h3>
                          <motion.p
                            initial={{ scaleY: 0.2 }}
                            whileInView={{ scaleY: 1 }}
                            transition={{ duration: 0.3 }}
                            viewport={{ once: true }}
                          >
                            {data?.description || "-"}
                          </motion.p>
                        </div>
                        <div className="img_info">
                          <div className="img_wrap">
                            <motion.img
                              initial={{ scaleY: 0.6 }}
                              whileInView={{ scaleY: 1 }}
                              transition={{ duration: 0.3 }}
                              viewport={{ once: true }}
                              src={`${fileBaseUrl}/${data?.image}`}
                              alt="Services"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Fragment>
            );
          })}

          <div className="btn_lines">
            <button type="button" onClick={prevHandler}>
              <MdArrowBack />
            </button>
            <div
              className="count"
              style={{
                backgroundColor: `${btnTheme[num % 6]}`,
              }}
            >
              <p className="circle">
                <b>
                  {num + 1}/{dataList?.length}
                </b>
              </p>
              <p>{dataList[num]?.name || "-"}</p>
            </div>
            <button type="button" onClick={nextHandler}>
              <MdArrowForward />
            </button>
          </div>
        </Fragment>
      ) : (
        <p className="text-center py-5">Properties data not found...</p>
      )}
    </div>
  );
};

export default Properties;
