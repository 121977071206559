import "./RoomTypes.scss";
import { Parallax } from "react-scroll-parallax";
import { motion } from "framer-motion";
import { fileBaseUrl } from "../../../utils/apiData";
import { Fragment, useState } from "react";
import ImagePreview from "../../../component/imagePreview/ImagePreview";
import PrimaryBtn from "../../../component/primaryBtn/PrimaryBtn";

const RoomTypes = ({ homePage, setContact }) => {
  const [modalShow, setModalShow] = useState(false);
  const [img, setImg] = useState("");

  const roomData = [
    {
      id: 0,
      img: homePage?.room_sec_1_image,
      title: homePage?.room_sec_1_title || "-",
      price: homePage?.room_sec_1_price_title || "-",
      detail: homePage?.room_sec_1_description || "-",
    },
    {
      id: 1,
      img: homePage?.room_sec_2_image,
      title: homePage?.room_sec_2_title || "-",
      price: homePage?.room_sec_2_price_title || "-",
      detail: homePage?.room_sec_2_description || "-",
    },
    {
      id: 2,
      img: homePage?.room_sec_3_image,
      title: homePage?.room_sec_3_title || "-",
      price: homePage?.room_sec_3_price_title || "-",
      detail: homePage?.room_sec_3_description || "-",
    },
    {
      id: 3,
      img: homePage?.room_sec_4_image,
      title: homePage?.room_sec_4_title || "-",
      price: homePage?.room_sec_4_price_title || "-",
      detail: homePage?.room_sec_4_description || "-",
    },
  ];

  return (
    <Fragment>
      <section className="room_types" id="rooms">
        <div className="content_wrap p_b">
          <Parallax translateX={[-5, 5]}>
            <h1 className="large">{homePage?.main_title_1 || "-"}</h1>
          </Parallax>
          <Parallax translateX={[5, -5]}>
            <h1 className="large">{homePage?.main_title_2 || "-"}</h1>
          </Parallax>

          <div className="rooms_grid">
            {roomData?.map((data, j) => {
              return (
                <motion.div
                  initial={{ translateY: 20, opacity: 0.4 }}
                  whileInView={{ translateY: 0, opacity: 1 }}
                  transition={{
                    duration: 0.5,
                    delay: `${0.1 * j}`,
                    ease: "easeIn",
                  }}
                  className="room_card"
                  key={data.id}
                
                >
                  <div className="img_wrap">
                    <img
                      src={`${fileBaseUrl}/${data?.img}`}
                      alt="Rooms"
                      loading="lazy"
                      onClick={() => {
                        setModalShow(true);
                        setImg(data?.img);
                      }}
                    />
                  </div>
                  <div className="text_detail">
                    <h5>
                      <b>{data.title}</b>
                    </h5>
                    <p className="price">
                      <b>{data.price}</b>
                      (Per person / Per month*)
                    </p>
                    <p>{data.detail}</p>
                  </div>
                </motion.div>
              );
            })}
          </div>
          <div className="btn_line">
          <PrimaryBtn theme="dark" title="Book Call" onClick={() => setContact(true)} />
          </div>
          <Parallax translateX={[-5, 5]}>
            <h1 className="extra_large">{homePage?.amenities_title_1}</h1>
          </Parallax>
          <Parallax translateX={[5, -5]}>
            <h1 className="extra_large">
              <span>{homePage?.amenities_title_2}</span>
            </h1>
          </Parallax>
        </div>
      </section>
      <ImagePreview show={modalShow} onHide={() => setModalShow(false)} img={img} />
    </Fragment>
  );
};

export default RoomTypes;
