import Modal from "react-bootstrap/Modal";
import { fileBaseUrl } from "../../utils/apiData";
import { IoMdClose } from "react-icons/io";

const ImagePreview = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <button type="button" className="close" onClick={props.onHide}><IoMdClose /></button>
        <img src={`${fileBaseUrl}/${props.img}`} alt="Roomo Preview" />
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreview;
